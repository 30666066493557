<template>
  <div class="order-info-table">
    <table>
      <tr>
        <!--退款编号-->
        <td width="33.3333%">{{$t('RefundNumber')}}：{{details.cancelId}}</td>
        <!--訂單編號-->
        <td width="33.3333%">{{$t('Order number')}}：{{order.orderNo}}</td>
        <!--預定時間-->
        <td width="33.3333%">{{$t('Scheduled time')}}：{{details.orderTime|formatDate('yyyy-MM-dd hh:mm')}}</td>
      </tr>
      <tr>
        <!--商戶名稱-->
        <td>{{$t('BusinessName')}}：{{details.businessName}}</td>
        <!--產品編號-->
        <td>{{$t('ProductNumber')}}：{{details.productNo}}</td>
        <!--產品名稱-->
        <td>{{$t('ProductName')}}：{{details.productTitle}}</td>
      </tr>
      <tr>
        <!--取消訂單發起方-->
        <td>{{$t('CancelOrderOriginator')}}：{{details.userObject}}</td>
        <!--取消原因-->
        <td>{{$t('CancellationReason')}}：{{details.cancelReasonName}}</td>
        <!--申請時間-->
        <td>{{$t('ApplicationTime')}}：{{details.addTime|formatDate('yyyy-MM-dd hh:mm')}}</td>
      </tr>
      <tr>
        <!--取消類型-->
        <td>{{$t('CancellationType')}}：{{details.cancelType}}</td>
        <!--退款方式-->
        <td>{{$t('RefundMethod')}}：{{details.refundMethod}}</td>
        <!--結算貨幣-->
        <td>{{$t('BillingCurrency')}}：{{$t('Currencys')[details.billingType]}}</td>
      </tr>
      <tr>
        <!--實付金額-->
        <td>{{$t('RealAmount')}}：{{details.totalMoney}}</td>
        <!--支付方式-->
        <td>{{$t('Payment method')}}：{{$t(details.payPlatform)}}</td>
      </tr>
      <tr>
        <!--支付交易號-->
        <td>{{$t('PaymentTransactionNumber')}}：{{details.payOrderId}}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>
  export default {
    name: 'RefundDetails',
    props: {
      details: Object,
      order: Object
    },
    mounted() {
    }
  };
</script>
<style scoped lang="less">
  .order-info-table{
    margin-top:40px;padding:16px 24px 16px 0;background-color:#f4f4f4;word-break:break-all;
    table{
      width:100%;
      td{padding-left:24px;padding-top:6px;padding-bottom:6px;}
    }
  }
</style>
