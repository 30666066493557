<template>
  <div class="new-order" v-if="orderInfo">
    <div class="fl-group">
      <div class="hd cl">
        <a class="fr btn" href="javascript:;" @click="openModal('contactClients')">{{$t('Contact customer')}}</a>
        {{$t('Order content')}}
      </div>
      <div class="order-info">
        <!--訂單編號-->
        <div class="p">{{$t('Order number')}}：{{orderInfo.orderNo}}</div>
        <!--產品編號-->
        <div class="p">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</div>
        <!--產品名稱-->
        <div class="p">{{$t('ProductName')}}：{{orderInfo.productTitle}}</div>
        <!--旅行團出發日-->
        <div class="p">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</div>
        <!--人數-->
        <div class="p">{{$t('Number of people')}}：{{totalPeople}}</div>
        <!--房間數目-->
        <div class="p" v-if="isDay">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</div>
        <!--訂單來源-->
        <div class="p">{{$t('Order Source')}}：{{orderInfo.orderSource|dictName('orderSource')}}</div>
      </div>
    </div>
    <!--旅客資料-->
    <div class="fl-group">
      <div class="hd">{{$t('Traveler information')}}</div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th></th>
            <th>{{$t('TravellerType')}}</th><!--旅客類型-->
            <th>{{$t('Appellation')}}</th><!--稱謂-->
            <th>{{$t('English name')}}</th>
            <th>{{$t('Gender')}}</th>
            <th>{{$t('Certificate Type')}}</th>
            <th>{{$t('Certificate of Issue')}}</th>
            <th>{{$t('Certificate Number')}}</th>
            <th>{{$t('Certificate Validity Period')}}</th>
            <th>{{$t('DateOfBirth')}}</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) of passengerInfo" :key="item.id">
            <td>{{$t('Passenger')}}{{index+1}}</td>
            <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
            <td>{{item.isCalled|dictName(appellationOptions)}}</td>
            <td>{{item.englishSurname}}/{{item.englishName}}</td>
            <td>{{item.genderType|dictName('gender')}}</td>
            <td>{{item.paperworkType|dictName(certificateTypeOptions)}}</td>
            <td>{{item.issuingCountry|dictName(countryOptions)}}</td>
            <td>{{item.idNumber}}</td>
            <td>{{item.effectiveDate}}</td>
            <td>{{item.birthday}}</td>
            <td class="nowrap">({{item.phoneType}}) {{item.userPhone}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--聯絡人資料-->
    <div class="fl-group" v-if="contactInformation">
      <div class="hd">{{$t('ContactInformation')}}</div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Appellation')}}</th>
            <th>{{$t('Contact Name')}}</th>
            <th>{{$t('Contact Number')}}</th>
            <th>{{$t('Email')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{contactInformation.isCalled|dictName(appellationOptions)}}</td>
            <td>{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</td>
            <td>({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</td>
            <td>{{contactInformation.userEmail}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--紧急聯絡人資料-->
    <div class="fl-group" v-if="emergencyContact&&emergencyContact.id">
      <div class="hd">{{$t('Emergency Contact')[0]}}</div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Name')}}({{$t('English')}})</th>
            <th>{{$t('Name')}}({{$t('Chinese')}})</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}</td>
            <td>{{emergencyContact.chineseSurname}}{{emergencyContact.chineseName}}</td>
            <td>({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd">{{$t('Detailed Charge')}}</div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Charging items')}}</th>
            <th>{{$t('Price')}}</th>
            <th>{{$t('Quantity')}}</th>
            <th>{{$t('Amount')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item of plusCost" :key="item.detailsId">
            <template v-if="item.cancelCategory==='last'">
              <td class="b" colspan="3">{{$t('Total')}}</td>
              <td class="b">{{item.feeAmount}}</td>
            </template>
            <template v-else>
              <td>{{item.cancelCategoryName}}</td>
              <td>{{item.unitPrice}}</td>
              <td>{{item.amount}}</td>
              <td>{{item.feeAmount}}</td>
            </template>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'NewOrder',
    props: {
      orderInfo: Object,
      passengerInfo: Array,
      contactInformation: Object,
      emergencyContact: Object,
      countryOptions: Array,
      certificateTypeOptions: Array,
      appellationOptions: Array,
      plusCost: Array,
      isDay: Number
    },
    computed: {
      totalPeople() {
        if (this.orderInfo.adultNumber) {
          return this.orderInfo.adultNumber + this.orderInfo.childNumber + this.orderInfo.notChildNumber + this.orderInfo.babyNumber;
        }
        return 0;
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../../assets/style/table";
  .new-order{text-align:left;}
  .fl-group{
    .hd{
      position:relative;margin:20px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .btn{
      display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;
      &.btn-gray{color:#999;border-color:#ccc;}
    }
  }
  .order-info{
    padding:20px;background:#f4f4f4;border-radius:4px;
    .p{
      margin-bottom:16px;
      &:last-child{margin-bottom:0;}
    }
  }
</style>
